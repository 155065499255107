(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modal.controller:ModalCtrl
   *
   * @description
   *
   */
  angular
    .module('modal')
    .controller('ModalCtrl', ModalCtrl);

  function ModalCtrl($uibModalInstance, $scope, Status, Rcr, RcrParent, $sce, $state, Events, $localStorage, UserTypes) {
    var vm = this;
    vm.ctrlName = 'ModalCtrl';
	
	$scope.$watch(function(){
		   return $scope.modal.body;
		}, function(value){
			$scope.modal.bodyhtml = $sce.trustAsHtml(value);
		}
	);
	
	
	$scope.ok = function(){
		if($scope.modal.statusRcr && !$scope.modal.statusRcr.isConfirm){
			//creates confirm new status modal
			$scope.modal.title = "Confirm change";
			$scope.modal.statusRcr.statusInd = parseInt($scope.modal.statusRcr.statusNew) - 1;
			$scope.modal.body = "Confirm you would like to change request " + $scope.modal.statusRcr.idsix + " from " + $scope.modal.statusRcr.statusOld + " to <strong>" + $scope.statusList[$scope.modal.statusRcr.statusInd].status + "</strong>.";
			$scope.modal.statusRcr.isConfirm = true;
		}else if($scope.modal.statusRcr){
			if ($scope.modal.statusRcr.statusNew == "6" || $scope.modal.statusRcr.statusNew == "7"){
				//used for approved or rejected dates
				var dateApproved = moment().utc().unix();
			}else{
				var dateApproved = null;
			}
			//saving of new status
			RcrParent.upsert({
				"id": $scope.modal.statusRcr.idsix,
				"statusId": $scope.modal.statusRcr.statusNew,
				"dateApproved": dateApproved
			}, function (respon) {
				var dataobj = {
					"statusOld": $scope.modal.statusRcr.statusIdOld,
					"statusNew": $scope.modal.statusRcr.statusNew
				}
				Events.create({
					"eventType":"rcr status change", 
					"userId": $localStorage.loggedInUser.id, 
					"rcrParentId": $scope.modal.statusRcr.idsix, 
					"eventData":dataobj
				});
				//send email notification if new status is 3 (more info), 6 (rej) or 7 (appr)
				switch ($scope.modal.statusRcr.statusNew){
					case "3":
					case 3:
						var args = {
							"rcrId": $scope.modal.statusRcr.idsix,
							"toAction": "", 
							"friendlyStatus": "pending further information"
						}
						vm.doSend(args);
						break;
					case "6":
					case 6:
						var args = {
							"rcrId": $scope.modal.statusRcr.idsix,
							"toAction": "no ", 
							"friendlyStatus": "rejected"
						}
						vm.doSend(args);
						break;
					case "7":
					case 7:
						var args = {
							"rcrId": $scope.modal.statusRcr.idsix,
							"toAction": "no ", 
							"friendlyStatus": "approved"
						}
						vm.doSend(args);
						break;
					default:
					console.log($scope.modal.statusRcr.statusNew);
				}
				
				$uibModalInstance.close();
				$state.reload();
				
			});
			
		}else if($scope.modal.hasDismiss){
			$uibModalInstance.close();
		}else{
			$uibModalInstance.close();
		}
		
	}
	
	$scope.userTypeOk = function(){
		if ($scope.modal.userType && $scope.modal.userType.newType){
			$localStorage.loggedInUser.userType = $scope.modal.userType.newType;
			UserTypes.find({"filter": {"where": {"id":$scope.modal.userType.newType}}}).$promise.then(
			function(results){ 
				$localStorage.loggedInUser.UserTypes = results[0];
				$uibModalInstance.close();
				$state.reload();
			});
		}
	}
	
	vm.doSend = function(args){
		RcrParent.findOne({"filter":{"where": {"id": args.rcrId}}}, function (parent) {
			args["userId"] = parent.userId;
			RcrParent.sendNotification(args);
		});
	}
	
	$scope.dismiss = function(){
		$uibModalInstance.close();
	}
	
	Status.find().$promise.then(
		function(results){
			$scope.statusList = results;
			
	});
  }
}());
